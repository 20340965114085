import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React from 'react'
import { ReactComponent as NextArrowIcon } from '@site/images/swiper/rightArrow.svg'
import { ReactComponent as PrevArrowIcon } from '@site/images/swiper/leftArrow.svg'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { Container } from './styles'
import { CarouselArrowWrapper } from '@site/components/atoms/CarouselArrowWrapper/CarouselArrowWrapper'

const Carousel = ( { children, initialSlide, autoPlaySpeed } ) => {
  const settings = {
    'autoplay': true,
    'autoplaySpeed': autoPlaySpeed,
    'infinite': true,
    'initialSlide': initialSlide || 0,
    'responsive': [
      {
        'breakpoint': 992,
        'settings': {
          'autoplay': true,
          'dots': false,
          'infinite': true,
        }
      },
    ],
    'speed': 1000,
    'dots': true,
    'nextArrow': <CarouselArrowWrapper ArrowIcon={ NextArrowIcon } />,
    'prevArrow': <CarouselArrowWrapper ArrowIcon={ PrevArrowIcon } />,
  }

  return (
    <Container >
      <Slider { ...settings }>
        { children }
      </Slider>
    </Container>
  )
}

Carousel.propTypes = {
  'autoPlaySpeed': PropTypes.number,
  'children': PropTypes.any.isRequired,
  'initialSlide': PropTypes.number,
}

Carousel.defaultProps = {
  'autoPlaySpeed': 10000,
  'initialSlide': 0,
}


export default Carousel
