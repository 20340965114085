import styled from 'styled-components'

export const Arrow = styled.div`
    .slick-arrow {
        height: 40px;
        width: 40px;
        @media(max-width: ${props => props.theme.mediaQuery.smallpc}) {
            height: 0px;
            width: 0px;
        }
        top: 300px;
    }
`
