import React from 'react'
import * as S from './styles'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { isMobile } from 'react-device-detect'
import BannerContent from '@site/components/molecules/BannerContent'
import { ReactComponent as MobileAnimation } from '@site/images/banner/mobile-animation.svg'
import { ReactComponent as DesktopAnimation } from '@site/images/banner/desktop-animation.svg'
import { HomeBanner } from '@shared/interfaces/HomeBanner'
import Carousel from '@site/components/molecules/Carousel'

const formatBanner = ( { title, subtitle, image, clock, button, warning }: Banner.Banner ) => {
  const mobileBanner = getImage( image.mobile1200x1200.localFile )
  const desktopBanner = getImage( image.desktop5000x1600.localFile )
  const bannerLocal = isMobile ? mobileBanner : desktopBanner
  const bannerImage = convertToBgImage( bannerLocal )

  return (
    <S.Image
      { ...bannerImage }
      className="banner-image"
      key={ title.text.replace( /\s+/g, '' ) }
    >
      <BannerContent
        title={ title }
        subtitle={ subtitle }
        clock={ clock }
        button={ button }
        warning={ warning }
      />
    </S.Image>
  )
}

const Banner = ( { banners }: Banner.Props ) => {
  if ( typeof window === 'undefined' ) {
    return null
  }

  return (
    <S.Container>
      <Carousel>
        { banners.map( formatBanner ) }
      </Carousel>
      <MobileAnimation className="mobile-animation" />
      <DesktopAnimation className="desktop-animation" />
    </S.Container>
  )
}

namespace Banner {
  export interface Props {
    banners: Banner[]
  }

  export interface Banner {
    title: HomeBanner.Title
    subtitle: HomeBanner.Subtitle | null
    image: HomeBanner.Image
    clock: HomeBanner.Clock | null
    button: HomeBanner.Button
    warning: HomeBanner.Warning | null
  }
}

export default Banner
