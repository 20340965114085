import React from 'react'
import { Arrow } from './styles'
import PropTypes from 'prop-types'

const CarouselArrowWrapper = ( { className, style, onClick, ArrowIcon }: { className: string, style:any, onClick: Function, ArrowIcon: any } ) => {
  return (
    <Arrow>
      <ArrowIcon
        className={ className }
        style={{ ...style }}
        onClick={ onClick }
      />
    </Arrow>
  )
}

CarouselArrowWrapper.propTypes = {
  'ArrowIcon': PropTypes.elementType.isRequired,
  'className': PropTypes.string.isRequired,
  'onClick': PropTypes.func.isRequired,
  'style': PropTypes.elementType.isRequired,
}

export { CarouselArrowWrapper }
