import styled from 'styled-components'

const Container = styled.div<Container.Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 128px;
  margin-bottom: 8px;

  h1 * {
    text-shadow: 0px 2px 8px rgba(19, 26, 45, 0.2);
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
    color: ${props => props.$color};
    white-space: pre-line;
  }

  h1 strong {
    letter-spacing: -0.03em;
    font-weight: 700;
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    max-width: 450px;
    min-height: 144px;

    h1 {
      margin-right: -10px;
    }
  }
`

namespace Container {
  export interface Props {
    $color: string
  }
}

export { Container }
