import styled from 'styled-components'

export const Container = styled.section`
    .slick-prev {
        left: 0;
        z-index: 1;
    }
    .slick-next {
        right: 0;
        z-index: 1;
    }
`
